import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Page.jsx";
import Divider from '../components/atoms/Divider';
import Spacer from '../components/atoms/Spacer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Requirements for KneeBoard`}</h2>
    <p>{`minimum iOS version 14.0`}</p>
    <Spacer mdxType="Spacer" />
    <h2>{`I found a bug. What should I do?`}</h2>
    <p>{`Just send an email to `}<a parentName="p" {...{
        "href": "mailto:stevenjselcuk@gmail.com"
      }}>{`stevenjselcuk@gmail.com`}</a>{` with the following information:`}</p>
    <ul>
      <li parentName="ul">{`The version of KneeBoard app you are using`}</li>
      <li parentName="ul">{`You device model/generation and iOS/iPadOS version`}</li>
      <li parentName="ul">{`The error message (if possible)`}</li>
      <li parentName="ul">{`Explain what you were doing when the error occurred`}</li>
      <li parentName="ul">{`Explaination for reproduce the error`}</li>
      <li parentName="ul">{`Screenshot (if possible)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      